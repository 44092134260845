export default [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/Login'),
        meta: {
            title: 'Educasis - Login'
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword'),
        meta: {
            title: 'Educasis - Recuperar Senha'
        }
    }
];