import { Model } from "@vuex-orm/core";
import http, { getToken, TOKEN_NAME } from "@/services";
import "regenerator-runtime/runtime";
import Institution from "@/models/Institution";
import User from "./User";

export default class Auth extends Model {
  static entity = "auth";

  static fields() {
    return {
      registration: this.string(""),
      password: this.string(""),
      user_id: this.number(null),
    };
  }

  static async login(registration, password) {
    const { response } = await this.api().post("/auth/login", {
      registration,
      password,
    });
    const { token } = response.data;
    localStorage.setItem(TOKEN_NAME, token);
  }

  static async forgotPassword(user_id, new_password) {
    const user = this.query().first();
    await this.api()
      .put("/auth/forgot-password", {
        user_id: user_id,
        password: new_password,
      })
      .finally(() => {
        this.delete(user.$id);
      });
  }

  static async getRegistration(registration) {
    const { response } = await this.api().get(
      `/auth/forgot-password/${registration}`
    );

    return { status: response.status === 200, user_id: response.data.user_id }
  }

  static logout() {
    User.query().deleteAll();
    Institution.query().deleteAll();
    this.api()
      .post(
        "/auth/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(() => {
        localStorage.removeItem("selectedYear");
        localStorage.removeItem(TOKEN_NAME);
      });
  }

  static async getImage() {
    const { data } = await http.get("/get-image");
    return data.ieducar_image;
  }
}
