import { Model } from "@vuex-orm/core";
import http, { getToken } from "@/services";
import "regenerator-runtime/runtime";
import {useRouter} from "vue-router";

export default class User extends Model {
  static entity = "users";

  static fields() {
    return {
      name: this.attr(""),
      id: this.attr(null),
      cod_instituicao: this.attr(null),
      role_id: this.attr(null),
      role_name: this.attr(""),
      is_teacher: this.attr(""),
      is_coordinator: this.attr(""),
      is_director: this.attr(""),
      is_institutional: this.attr(""),
      is_admin: this.attr(""),
      permissions: this.attr([]),
      last_year_access: this.attr(""),
    };
  }

  static async me() {
    try {
      await this.api().get("/auth/me", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

    } catch (e) {
      const router = useRouter();
      await router.push({ name: 'login' })
    }
  }
}
