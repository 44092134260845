import axios from "axios";
import "regenerator-runtime/runtime";
import router from "@/router";

export const TOKEN_NAME = "bearer_token";

export function getToken() {
  return localStorage.getItem(TOKEN_NAME);
}

function getSelectedYear() {
  return localStorage.getItem("selectedYear");
}

export function getAuthorization() {
  return `Bearer ${getToken()}`;
}

const http = axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    Accept: "application/json"
  }
});

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response && response.status === 500) {
      $swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor. Entre em contato com o suporte!"
      });
    }

    if (response && response.status === 422) {
      let message = "";
      Object.keys(response.data.errors).forEach(item => {
        let field = response.data.errors[item];
        for (let i = 0; i < field.length; i++) {
          message += field[i] + "<br />";
        }
      });

      $swal.fire("Erro", message, "error");
    }

    if (response && response.status === 404) {
      const { error } = response.data;
      $swal.fire({
        icon: "warning",
        title: "Erro!",
        text: error
      });
    }

    if (response && response.status === 403) {
      const { error } = response.data;
      $swal.fire({
        icon: "warning",
        title: "Erro!",
        text: error
      });
    }

    if (response && response.status === 401) {
      $swal
        .fire({
          icon: "warning",
          title: "Sessão expirada!",
          text: "Por favor, realize o login"
        })
        .then(() => {
          const originalRoute = router.currentRoute.value.fullPath;
          router.push({
            name: "login",
            query: { redirect: originalRoute }
          });
        });
    }

    return Promise.reject(error);
  }
);

export default http;
